<!--
 * @Description:客服服务统计分析 customerServiceAnalyzeStatistics
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-11-23 11:02:05
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='mainbody'>
    <el-row class="lineOne">
      <el-row class="title">客服服务统计分析</el-row>
      <el-row class="selectLeft">
        <el-select v-model="value"
                   placeholder="请选择">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-row>
    </el-row>

    <el-row class="lineTwo">

      <el-row class="item"
              style="background: linear-gradient(92deg, #3FA9E5 0%, #1278B1 100%)">
        <el-row class="itemleft">
          <el-row class="itemleftOne">今日咨询</el-row>
          <el-row class="itemleftTwo">264</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsOne.png"
               alt="">
        </el-row>
      </el-row>

      <el-row class="item"
              style="background: linear-gradient(89deg, #46E6FC 0%, #1198B3 100%);">
        <el-row class="itemleft">
          <el-row class="itemleftOne">历史咨询</el-row>
          <el-row class="itemleftTwo">313316</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsTwo.png"
               alt="">
        </el-row>
      </el-row>

      <el-row class="item"
              style="background: linear-gradient(93deg, #3EA8E4 0%, #1278B1 100%);">
        <el-row class="itemleft">
          <el-row class="itemleftOne">待解决反馈</el-row>
          <el-row class="itemleftTwo">641</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsThree.png"
               alt="">
        </el-row>
      </el-row>

      <el-row class="item"
              style="background: linear-gradient(93deg, #A8AEFF 0%, #2548F2 100%);">
        <el-row class="itemleft">
          <el-row class="itemleftOne">已解决反馈</el-row>
          <el-row class="itemleftTwo">62541</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsFour.png"
               alt="">
        </el-row>
      </el-row>

      <el-row class="item"
              style="background: linear-gradient(89deg, #46E5FB 0%, #1199B4 100%);">
        <el-row class="itemleft">
          <el-row class="itemleftOne">各平台平均分</el-row>
          <el-row class="itemleftTwo">4.6</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsFive.png"
               alt="">
        </el-row>
      </el-row>

      <el-row class="item"
              style="margin-right:0;background: linear-gradient(268deg, #BF78E9 0%, #E1BCF7 100%);">
        <el-row class="itemleft">
          <el-row class="itemleftOne">反馈解决比例</el-row>
          <el-row class="itemleftTwo">89%</el-row>
        </el-row>
        <el-row class="itemright">
          <img src="@/assets/newStatistictIcon/customerServiceAnalyzeStatisticsSix.png"
               alt="">
        </el-row>
      </el-row>

    </el-row>

    <el-row class="lineThree">
      <el-row class="lineLeft">
        <el-row class="title">用户反馈分类</el-row>
        <el-row class="chartsDiv"
                id="topLeft"
                :option="topLeftOptions">
        </el-row>
      </el-row>
      <el-row class="lineRight">
        <el-row class="title">用户反馈统计</el-row>
        <el-row class="chartsDiv"
                id="topRight"
                :option="topRightOptions">
        </el-row>
      </el-row>
    </el-row>

    <el-row class="lineFour">
      <el-row class="title">客服坐席呼叫统计</el-row>
      <el-row class="chartsDiv"
              id="bottom"
              :option="bottomOptions">
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as echarts from 'echarts';
import { mapState } from 'vuex'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          labelColor: "#ffffff"
        },
        light: {
          labelColor: "#000000"
        }
      },

      value: '2020',
      options: [
        { label: '2019', value: '2019' },
        { label: '2020', value: '2020' },
        { label: '2021', value: '2021' },
        { label: '2022', value: '2022' },
      ],
      topLeft: "",
      topLeftOptions: {},

      topRight: "",
      topRightOptions: {},

      bottom: "",
      bottomOptions: {}
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initCharts()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initCharts()
  },
  //方法集合
  methods: {
    initCharts () {
      this.queryTopLeftCharts()
      this.queryTopRightCharts()
      this.queryBottomCharts()
    },
    queryTopLeftCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('topLeft'));
      let dataValue = [6, 8, 10, 10]
      let indicatorData = [
        {
          text: '功能建议',
          max: "10"
        },
        {
          text: '界面优化',
          max: "10"
        },
        {
          text: '产品BUG',
          max: "10"
        },
        {
          text: '其他问题',
          max: "10"
        }
      ]
      myChart.setOption({
        backgroundColor: '',
        tooltip: {},
        radar: [{
          name: {
            formatter: '{value}',
            textStyle: {
              color: labelColor,
              fontSize: 16
            }
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: '#00487A'
            }
          },
          splitArea: {
            areaStyle: {
              opacity: 0,
            }
          },
          indicator: indicatorData,
          center: ['50%', '50%'],
          radius: '65%'
        }],
        series: [{
          type: 'radar',
          symbolSize: 0,
          areaStyle: {
            normal: {
              opacity: 0
            }
          },
          itemStyle: {
            color: '#FFE216'
          },
          data: [{
            value: dataValue,
            name: '传统控制'
          }]
        }]

      })
    },
    queryTopRightCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('topRight'));
      let leftNumber = [32, 58, 64, 64, 64, 25, 32, 58, 64, 64, 64, 25];
      let rightNumber = [12, 58, 64, 64, 64, 25, 32, 58, 64, 64, 64, 25];
      let sumNumber = [35, 59, 88, 66, 66, 30, 37, 60, 70, 74, 74, 29];
      let xData = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月',];
      let dom = 400;
      let barWidth = dom / 20;
      let leftColors = [];
      let rightColors = [];
      for (let i = 0; i < 10; i++) {
        leftColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 1,
              color: '#2144BC', // 最左边
            },
            {
              offset: 0.5,
              color: '#27318D', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#5172E1', // 右边的左边 颜色
            }
          ],
        });
        rightColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 1,
              color: '#DA8400', // 最左边
            },
            {
              offset: 0.5,
              color: '#DA8400', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#E8A900', // 右边的左边 颜色
            },
          ],
        });
      }
      myChart.setOption({
        backgroundColor: '',
        //提示框
        tooltip: {
          trigger: 'axis',
          formatter: function (p) {
            let div = `
                ${p[0].name}
                <br/>
                ${p[0].seriesName}:${p[0].value}
                <br/>
                ${p[3].seriesName}:${p[3].value}
                <br/>
                 ${p[6].seriesName}:${p[6].value}
            `;
            return div;
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '6%',
          right: '3%',
          top: '10%',
          bottom: '15%',
        },
        //X轴
        xAxis: {
          data: xData,
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#00487A',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 15,
            color: labelColor,
          },
        },
        yAxis: {
          show: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#00487A',
            },
          },
          axisLabel: {
            color: labelColor,
            margin: 20,
            fontSize: 15,
          },
        },
        series: [
          {
            name: '用户反馈',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: function (params) {
                  return leftColors[params.dataIndex % 7];
                },
              },
            },
            data: leftNumber,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: leftNumber,
            symbol: 'diamond',
            symbolOffset: ['-63%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                color: function (params) {
                  return leftColors[params.dataIndex % 7];
                },
              },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: leftNumber,
            symbol: 'diamond',
            symbolOffset: ['-63%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: function (params) {
                  return leftColors[params.dataIndex % 7].colorStops[0].color;
                },
              },
            },
          },
          // ---------------------分割线---------------------
          {
            name: '反馈解决',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: function (params) {
                  return rightColors[params.dataIndex % 7];
                },
              },
            },
            data: rightNumber,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: rightNumber,
            symbol: 'diamond',
            symbolOffset: ['63%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                color: function (params) {
                  return rightColors[params.dataIndex % 7];
                },
              },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: rightNumber,
            symbol: 'diamond',
            symbolOffset: ['63%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: function (params) {
                  return rightColors[params.dataIndex % 7].colorStops[0].color;
                },
              },
            },
          },
          // ---------------------分割线--------------
          {
            name: '占比',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              color: '#fff',
              shadowColor: '#00EEFF',
              shadowBlur: 20,
              borderColor: '#00EEFF',
              borderWidth: 5,
            },
            lineStyle: {
              width: 4,
              color: '#00EEFF',
              shadowColor: '#00EEFF',
              shadowBlur: 20,
            },
            data: sumNumber,
          },
        ],
      })
    },
    queryBottomCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('bottom'));
      let leftNumber = [32, 58, 64, 64, 64, 25, 32, 58, 64, 64, 64, 25];
      let rightNumber = [12, 58, 64, 64, 64, 25, 32, 58, 64, 64, 64, 25];
      let xData = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月',];
      let dom = 400;
      let barWidth = dom / 20;
      let leftColors = [];
      let rightColors = [];
      for (let i = 0; i < 10; i++) {
        leftColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 1,
              color: '#2144BC', // 最左边
            },
            {
              offset: 0.5,
              color: '#27318D', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#5172E1', // 右边的左边 颜色
            }
          ],
        });
        rightColors.push({
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 1,
              color: '#DA8400', // 最左边
            },
            {
              offset: 0.5,
              color: '#DA8400', // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: '#E8A900', // 右边的左边 颜色
            },

          ],
        });
      }
      myChart.setOption({
        backgroundColor: '',
        //提示框
        tooltip: {
          trigger: 'axis',
          formatter: function (p) {
            let div = `
                ${p[0].name}
                <br/>
                ${p[0].seriesName}:${p[0].value}
                <br/>
                ${p[3].seriesName}:${p[3].value}
            `;
            return div;
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '4%',
          right: '2%',
          top: '12%',
          bottom: '15%',
        },
        //X轴
        xAxis: {
          data: xData,
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#00487A',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 15,
            color: labelColor,
          },
        },
        yAxis: {
          show: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#00487A',
            },
          },
          axisLabel: {
            color: labelColor,
            margin: 20,
            fontSize: 15,
          },
        },
        series: [
          {
            name: '呼叫请求',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: function (params) {
                  return leftColors[params.dataIndex % 7];
                },
              },
            },
            data: leftNumber,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: leftNumber,
            symbol: 'diamond',
            symbolOffset: ['-63%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                color: function (params) {
                  return leftColors[params.dataIndex % 7];
                },
              },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: leftNumber,
            symbol: 'diamond',
            symbolOffset: ['-63%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: function (params) {
                  return leftColors[params.dataIndex % 7].colorStops[0].color;
                },
              },
            },
          },
          // ---------------------分割线---------------------
          {
            name: '呼叫响应',
            type: 'bar',
            barWidth: barWidth,
            itemStyle: {
              normal: {
                color: function (params) {
                  return rightColors[params.dataIndex % 7];
                },
              },
            },
            data: rightNumber,
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: rightNumber,
            symbol: 'diamond',
            symbolOffset: ['63%', '50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                color: function (params) {
                  return rightColors[params.dataIndex % 7];
                },
              },
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: rightNumber,
            symbol: 'diamond',
            symbolOffset: ['63%', '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: function (params) {
                  return rightColors[params.dataIndex % 7].colorStops[0].color;
                },
              },
            },
          },
        ],
      })
    },
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发

}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  .lineOne {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include background('chartsTitleBack');
    .title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 37px;
      @include font_color('chartsTitleColor');
      opacity: 1;
    }
    .selectLeft {
      position: absolute;
      right: 50px;
    }
  }
  .lineTwo {
    width: 100%;
    height: 8%;
    display: flex;
    margin-top: 1%;
    .item {
      width: 24%;
      display: flex;
      margin-right: 1%;
      .itemleft {
        width: 70%;
        text-align: center;
        color: #ffffff;
        .itemleftOne {
          height: 50%;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 40px;
        }
        .itemleftTwo {
          height: 50%;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
        }
      }
      .itemright {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 52px;
          height: 52px;
        }
      }
    }
  }
  .lineThree {
    width: 100%;
    height: 40%;
    margin-top: 1%;
    display: flex;
    .lineLeft {
      width: 28%;
      @include background('chartsBack');
      .title {
        width: 100%;
        height: 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        @include font_color('chartsTitleColor');
        opacity: 1;
        padding-top: 3.3%;
        padding-left: 3%;
      }
      .chartsDiv {
        width: 100%;
        height: 90%;
      }
    }
    .lineRight {
      width: 71%;
      margin-left: 1%;
      @include background('chartsBack');
      .title {
        width: 100%;
        height: 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        @include font_color('chartsTitleColor');
        opacity: 1;
        padding-top: 1%;
        padding-left: 1.4%;
      }
      .chartsDiv {
        width: 100%;
        height: 90%;
      }
    }
  }
  .lineFour {
    width: 100%;
    height: 38%;
    margin-top: 1%;
    @include background('chartsBack');
    .title {
      width: 100%;
      height: 30px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 26px;
      @include font_color('chartsTitleColor');
      opacity: 1;
      padding-top: 1%;
      padding-left: 0.8%;
    }
    .chartsDiv {
      width: 100%;
      height: 90%;
    }
  }
}
</style>